import React, { Component } from 'react'
import { Accordion, Icon , Button} from 'semantic-ui-react'
import { Link } from 'gatsby';
import { connect } from 'react-redux';

import { update_Page, removeJunctionPage } from '../../actions';
import JunctionPageDelete from './JunctionPageDelete';
//import PageEdit from './PageEdit';

import JunctionPageEdit from './JunctionPageEdit'

class JunctionPagesSection extends Component {
  constructor(props) {
  super(props);
  this.file = null;
  this.state = {
                id: props.junction_page.id,
                title: props.junction_page.title,
                user: props.user,
                activeIndex: 1};
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }
  handleDelete = (event) => {
    console.log('this.state.user')
    console.log(this.state.user)
    this.props.removeJunctionPage(this.props.junction_page, this.state.user);
  }


  handleSubmit = () => {
    this.props.page_select(this.state.page_id, this.state.title)
  };

  render() {
    const { activeIndex } = this.state
    return (
      <div>
        <Accordion styled style={{marginTop:10}}>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.handleClick}
          >
            <Icon name='dropdown' />
            {this.state.title}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <h4>Title:  {this.props.junction_page.title}</h4>
            {this.props.user_access !== 'basic' ? (
              <div style={{display: 'inline'}}>
              {/* <PageEdit
                page={this.props.page}
                updatePage={this.props.update_Page}
                user={this.state.user}
              /> */}
              <JunctionPageDelete
                deleteJunctionPage={this.handleDelete}
                junctionPageTitle={this.props.junction_page.title}
              />

            </div>
          ):(
            <></>
          )}
          <JunctionPageEdit 
            junction_page={this.props.junction_page}
            user={this.state.user}
          />
          </Accordion.Content>
        </Accordion>
      </div>
    );
  }
}

export default connect(null, { update_Page, removeJunctionPage })(JunctionPagesSection);
