import React from 'react'
import { Form, Button, Modal, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { add_junctionPage} from '../../actions';

class PageNew extends React.Component {
  constructor(props) {
  super(props);
  this.state = {title: '',
                modalOpen: false,
                user: props.user
              };
  this.handleChange = this.handleChange.bind(this);
  this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOpen = () => this.setState({  modalOpen: true,
                                      title: ''
                                    })
  handleClose = () => this.setState({ modalOpen: false })


  handleChange(event) {
    const name = event.target.name;
    this.setState({[name]: event.target.value});
  }
  componentDidMount(){
    this.setState({title:''})
  }


  handleSubmit = (event) => {
    event.preventDefault();
      this.props.add_junctionPage({
        title: this.state.title,
        junction_data: "[]",
        user_id: this.props.user['custom:trust']
      });
      this.setState({ modalOpen: false })
    }


  render() {
    return (
      <Modal
        trigger={<Button primary onClick={this.handleOpen}>New Junction Page</Button>}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        size={'mini'}
        >
        <Modal.Header>Add New Junction Page</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>
                Name:
                <input
                  name="title"
                  type="text"
                  value={this.state.title}
                  onChange={this.handleChange}
                />
              </label>
              <Divider/>
              <Button
                primary
                onClick={this.handleSubmit}
                content="Save"
              />
              <Button
                secondary
                onClick={this.handleClose}
                content="Close"
              />
            </Form.Field>
          </Form>
        </Modal.Content>
      </Modal>
    )
  }
}
export default connect(null, { add_junctionPage })(PageNew);
