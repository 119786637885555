import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  update_JunctionPage,
  fetchPathways,
  fetchJunctionPages,
  fetchPages,
} from "../../actions";

import _ from "lodash";

import {
  Dropdown,
  Segment,
  Button,
  Divider,
  Form,
  Grid,
  Icon,
} from "semantic-ui-react";

import JunctionPagesList from "./JunctionPagesList";
import JunctionPageNew from "./JunctionPageNew";

const JunctionPageEdit = ({ id, junction_page }) => {
  const [loading, update_loading] = useState(true);
  const dispatch = useDispatch();
  const pathways = useSelector((state) => state.pathways);
  const pages = useSelector((state) => state.pages);
  const junctionPages = useSelector((state) => state.junctionPages);
  const user = useSelector((state) => state.user);

  const [redirect_data, updateRedirectData] = useState([]);
  const [selectedPathway, updateSelectedPathway] = useState("");

  const [dropdown_pathways, update_dropdown_pathways] = useState([]);

  useEffect(() => {
    console.log(user["custom:trust"]);
    dispatch(fetchPathways(user["custom:trust"]));
    dispatch(fetchPages(user["custom:trust"]));
    update_dropdown_pathways(pathways);
    const junction_data = JSON.parse(junction_page.junction_data);
    updateRedirectData(junction_data);

    // remove dropdowns
    var new_dropdown = [...pathways];
    junction_data.map((junc) => {
      _.remove(new_dropdown, { id: junc.id });
    });
    update_dropdown_pathways(new_dropdown);
  }, []);

  useEffect(() => {
    const junction_data = JSON.parse(junction_page.junction_data);
    updateRedirectData(junction_data);
  }, [id]);

  function returnListPathways() {
    return (
      <Segment>
        <h4>Select Pathway to add to junction:</h4>
        <Grid columns="equal">
          <Grid.Column width={10}>
            <Dropdown
              placeholder="Select Page"
              fluid
              selection
              search
              options={dropdown_pathways.map((pathway) => ({
                key: pathway.id,
                value: pathway.id,
                text: pathway.title,
              }))}
              onChange={(e, { value }) => updateSelectedPathway(value)}
            />
          </Grid.Column>
          <Grid.Column>
            <Button
              primary
              style={{ width: "100%" }}
              onClick={() => addNewJunction()}
            >
              Add Junction
            </Button>
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }

  function updateSelectedPage(value, index) {
    var arr = [...redirect_data];
    arr[index].destination = value;
    console.log(arr[index]);
    updateRedirectData(arr);
  }

  function handleChangeTitle(event, index) {
    var arr = [...redirect_data];
    arr[index].title = event.target.value;
    updateRedirectData(arr);

    // this.setState({[name]: event.target.value});
  }

  function returnJunctions() {
    const returning_patways = redirect_data.map((data, index) => {
      return (
        <div style={{ maxWidth: 600, marginTop: 20 }}>
          {/* <Button floated={"right"}>Remove</Button> */}
          <Button
            basic
            floated={"right"}
            negative
            onClick={() => removeJunction(index)}
            icon="trash"
          />
          <h4>{data.title}</h4>

          <Form>
            <Form.Field>
              <label>
                Title:
                <input
                  name="title"
                  type="text"
                  value={redirect_data[index].title}
                  onChange={(event) => handleChangeTitle(event, index)}
                />
              </label>
              <label>Page</label>
              <Dropdown
                placeholder="Select Page"
                fluid
                selection
                search
                defaultValue={redirect_data[index].destination}
                selectedLabel={redirect_data[index].destination}
                options={pages.map((page) => ({
                  key: page.page_id,
                  value: page.page_id,
                  text: page.title,
                }))}
                onChange={(e, { value }) => updateSelectedPage(value, index)}
              />
            </Form.Field>
          </Form>
          <Divider />
        </div>
      );
    });
    return (
      <div>
        <h3>Pathways:</h3>
        {returning_patways}
      </div>
    );
  }

  function addNewJunction() {
    // Remove the pathway from the dropdown
    removePathwayFromDropdown(selectedPathway, dropdown_pathways);

    //Update redirect redirect_data
    var arr = [...redirect_data];
    const selected_pathway = _.find(pathways, { id: selectedPathway });
    arr.push({
      title: selected_pathway.title,
      id: selected_pathway.id,
      destination: "",
    });
    updateRedirectData(arr);
  }

  function removeJunction(index) {
    var arr = [...redirect_data];
    arr.splice(index, 1);
    updateRedirectData(arr);
  }

  function removePathwayFromDropdown(selected_pathway, dropdowns) {
    console.log("removing from dropdown");
    console.log(selected_pathway);
    var new_dropdown = [...dropdowns];
    _.remove(new_dropdown, { id: selected_pathway });
    update_dropdown_pathways(new_dropdown);
  }

  function saveData() {
    console.log("here");
    //var obj = {...page}
    var new_data = JSON.stringify(redirect_data);
    console.log(new_data);
    //obj.junction_data = new_data
    dispatch(
      update_JunctionPage({ id: junction_page.id, junction_data: new_data })
    );
  }

  return (
    <Segment style={{ background: "#efefef" }} color="blue">
      {returnListPathways()}
      <Segment>{returnJunctions()}</Segment>
      <Divider />
      <div style={{ backgroundColor: "white" }}>
        <Button
          basic
          color="blue"
          style={{
            width: "100%",
            backgroundColor: "white",
            background: "none",
          }}
          onClick={() => saveData()}
        >
          Save Junctions
        </Button>
      </div>
    </Segment>
  );
};

export default JunctionPageEdit;
