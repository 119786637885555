import React from 'react';
import JunctionPagesSection from './JunctionPagesSection'

const JunctionPagesList = props => {
  const pages = props.junctionPages.map(junction_page => {
    return (
      <JunctionPagesSection
        junction_page={junction_page}
        key={junction_page.id}
        // page_select={props.page_select}
        user={props.user}
        // user_access={props.user_access}
        />
      )
  });

  return <div>{pages}</div>
};

export default JunctionPagesList;
